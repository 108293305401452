import { useRouter } from 'next/router';
import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSnackbar } from '@hooks/snackbar';
import { useUser } from '@hooks/user';
import {
  IGetCourseByStudentParams,
  IUpdateStudentCourse,
  IUpdateStudentCourseLesson,
} from '@services/content/courseByStudent';
import { ICourseState, ICourseContext } from './types';
import { CourseActions } from './actions';

const CourseContext = createContext({} as ICourseContext);

const courseActions = new CourseActions();

const CourseProvider: React.FC = ({ children }) => {
  const { user } = useUser();
  const { query } = useRouter();
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ICourseState>({} as ICourseState);
  const { course, currentLesson, modules } = state;

  const onError = useCallback(
    (message: string) =>
      showSnackbar({
        message,
        type: 'info',
        duration: 50000,
      }),
    [showSnackbar],
  );

  const onLoad = useCallback(load => setLoading(load), []);

  const getCourse = useCallback(
    async (data: IGetCourseByStudentParams) => {
      await courseActions.getCourse({
        onError,
        onLoad,
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      });
    },
    [onError, onLoad],
  );

  const handleCurrentLesson = useCallback(
    async (data: IUpdateStudentCourse) => {
      await courseActions.handleCurrentLesson({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        state: {
          modules,
        },
      });
    },
    [modules],
  );

  const handleCheckLesson = useCallback(
    async (data: { lesson: string; hasCompleted: boolean }) => {
      await courseActions.handleCheckLesson({
        data,
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
        state: {
          modules,
          course,
        },
      });
    },
    [course, modules],
  );

  const handleVideoTime = useCallback(
    async (data: IUpdateStudentCourseLesson) => {
      await courseActions.handleVideoTime({
        data,
        state: {
          modules,
        },
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      });
    },
    [modules],
  );

  const goNextLesson = useCallback(async () => {
    await courseActions.goNextLesson({
      state,
      onSuccess: newState =>
        setState(prevState => ({ ...prevState, ...newState })),
    });
  }, [state]);

  const getAllLessonTypeQuiz = useCallback(
    (data: { courseId: string; studentId: string }) =>
      courseActions.getAllLessonTypeQuiz(data),
    [],
  );

  const handleCurrentLessonBySlug = useCallback(
    (data: { lessonSlug: string }) =>
      courseActions.handleCurrentLessonBySlug({
        data,
        state: {
          course,
          modules,
        },
        onSuccess: newState =>
          setState(prevState => ({ ...prevState, ...newState })),
      }),
    [course, modules],
  );

  useEffect(() => {
    if (
      query?.slug &&
      query.slug !== '[slug]' &&
      query.slug !== course?.slug &&
      user?._id
    ) {
      getCourse({ courseSlug: query.slug as string });
    }
  }, [course?.slug, getCourse, query, user?._id]);

  return (
    <CourseContext.Provider
      value={{
        course,
        modules,
        currentLesson,
        loading,
        handleCheckLesson,
        handleCurrentLesson,
        handleVideoTime,
        goNextLesson,
        getAllLessonTypeQuiz,
        handleCurrentLessonBySlug,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const useCourse = (): ICourseContext => {
  const context = useContext(CourseContext);

  if (!context) {
    throw new Error('Course must be used within a CourseProvider');
  }

  return context;
};

export { useCourse, CourseProvider };
